<template>
  <div>
    <vs-popup
      id="listar-parcelas-modal"
      title=" "
      :active.sync="showParcelasModal"
    >
      <h2 class="text-primary">Boletos</h2>
      <vs-table
        class="table-list mt-5"
        maxHeight="600px"
        :data="parcelas"
        :maxItems="parcelas.length"
        :hoverFlat="true"
      >
        <template slot="thead">
          <vs-th class="text-center">
            Descrição
          </vs-th>
          <vs-th class="text-center">
            Vencimento
          </vs-th>
          <vs-th v-if="!unicoBotaoDownload"></vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
            <vs-td class="text-center">
              {{ item.Description }}
            </vs-td>
            <vs-td class="text-center">
              {{ $utils.dateToDDMMYYYY(item.DueDate) }}
            </vs-td>
            <vs-td class="text-center" v-if="!unicoBotaoDownload">
              <span class="flex">
                <vs-button
                  radius
                  color="primary"
                  type="flat"
                  icon="get_app"
                  v-if="item.BankSlipArchiveId > 0"
                  @click="downloadArchive((billets[indextr] || []).URL)"
                ></vs-button>
              </span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="btn-download" v-if="unicoBotaoDownload">
        <vs-button
          radius
          color="primary"
          type="flat"
          icon="get_app"
          v-if="parcelas.length > 0"
          @click="downloadArchive((billets[0] || []).URL)"
          >Download do boleto</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import axiosInstance from "@/axios";

export default {
  name: "listar-parcelas-popup",
  props: {
    autoLoad: {
      type: Boolean,
      required: true
    },
    parcelasModal: {
      type: Boolean,
      required: true
    },
    propostaUniqueId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      parcelas: [],
      billets: [],
      unicoBotaoDownload: false,
    };
  },
  watch: {
    propostaUniqueId(newVal, oldVal) {
      this.carregarParcelas(this.propostaUniqueId);
    }
  },
  computed: {
    showParcelasModal: {
      get() {
        return this.parcelasModal;
      },
      set() {
        this.$emit("fecharParcelasModal");
      }
    }
  },
  mounted() {
    if (this.autoLoad) {
      console.log(this.propostaUniqueId);
      if (this.propostaUniqueId) {
        this.carregarParcelas(this.propostaUniqueId);
      } else {
        this.carregarParcelas(this.$route.params.policyUniqueId);
      }
    }
  },
  methods: {
    carregarParcelas(uniqueId) {
        return axiosInstance
          .get(
            `/api/Proposal/GetProposalInstallments?proposalUniqueId=${uniqueId}`
          )
          .then(response => (response.data || {}).Response)
          .then(response => {
            this.parcelas = response.Installments;
            this.billets = response.Billets;
            this.unicoBotaoDownload = response.HasOneBillet;
            this.$emit("setParcelas", response.Installments);
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
    },
    downloadArchive(urlArchive) {
      if(!urlArchive){
        this.$onpoint.errorModal("Boleto indisponível");
      }
      window.open(urlArchive, "_self")
    },
  }
};
</script>

<style lang="scss">
@import "../../../../assets/scss/mixins";
$margin-color: #f6f6f6;
#listar-parcelas-modal {
  z-index: 53001;
}
#listar-parcelas-modal {
  .vs-popup {
    width: 800px !important;
    max-height: 800px !important;
  }
}
#listar-parcelas-modal {
  .vs-popup {
    .vs-popup--content {
      padding: 0 20px 20px 20px;
      i {
        font-size: 25px;
      }
    }
  }
  .vs-table--search {
    max-width: initial;
    margin-bottom: 30px;
    i {
      font-size: 2em;
      @include sm {
        margin-top: -22px;
      }
    }
    input {
      padding-left: 40px !important;
      font-size: 1.4em !important;
      width: 370px;
      height: 47px;
      @include sm {
        margin-top: -22px;
      }
    }
  }
  th .sort-th,
  th .vs-table-text {
    display: inline-flex !important;
    color: #9c9c9c !important;
  }
  th {
    color: #9c9c9c !important;
    text-transform: uppercase;
    font-weight: bold !important;
  }
  th .sort-th .th-sort-icon {
    font-size: 1.5rem;
  }
  .vs-con-table .vs-con-tbody {
    background: transparent !important;
    border: none !important;
    overflow-x: auto;
  }
  thead tr {
    height: 0;
    th {
      padding-bottom: 0;
    }
  }
  .tr-values {
    height: 80px;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 30px;
    // position: relative;
    // display: block;
    // overflow: auto;
  }
  td {
    border-top: 1px solid $margin-color;
    border-bottom: 1px solid $margin-color;
    padding: 15px;
    min-height: 25px;
    cursor: default;
    button {
      cursor: pointer;
      padding: 8px !important;
      font-size: 12px;
    }
  }
  td:first-child {
    border-left: 1px solid $margin-color !important;
    padding-left: 30px;
  }
  td:last-child {
    border-right: 1px solid $margin-color !important;
    padding-right: 30px;
  }
  .btn-download {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
